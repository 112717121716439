/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import NewHeader from "./newHeader"

import { theme } from "./theme"
import { createGlobalStyle } from "styled-components"
import { ThemeProvider } from "@material-ui/core/styles"

import Footer from "./footer"
import bg from "../../images/bg2.jpg"
import Frame from "./pageBorder"
import MusicButton from "../buttons/musicButton"

const GlobalStyle = createGlobalStyle`

  body {
    background-color: #000;
    color: white;
    font-family: ${theme.typography.fontFamily[1]} ;
    overflow-x: hidden;
    min-height: 100vh;
    line-height:150%;
    margin: 0;
    ${"" /* background image + overlay */}
    &::after {
      content: "";
      background: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0.15;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: fixed;
      z-index: -1;   
    }

    a {
      color: ${theme.palette.primary.main};
      transition: 0.4s
    }

    a:visited {
      color: ${theme.palette.primary.main} 
    }

    a:hover {
      color: white
    }

    p, a {
      line-height: 150%;
      font-family: ${theme.typography.fontFamily[1]};
    }

    font-weight: 400;
    font-size: 1.2rem;

    main {

      audio {
        width: 100%;
      }

      min-height: 65vh;

      @media (max-width: 1200px) {
        min-height: 65vh;
      }

      @media (max-width: 500px) {
        min-height: 60vh; 
      }

    }

    .prueba {
      transition: 2s;
    }

  }

`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MusicButton />
      <Frame />
      <NewHeader />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
