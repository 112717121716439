import { createMuiTheme } from "@material-ui/core/styles"

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f9a602",
    },
    secondary: {
      main: "#9b1924",
    },
    spacing: [0, 2, 3, 5, 8],
    success: {
      main: "#4caf50",
      darker: "#358538",
    },
    error: {
      main: "#d32f2f",
    },
    info: {
      main: "#333",
    },
    header: {
      title: "#ddd",
      bg: "#0e0e0e",
    },
    footer: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Marcellus SC", "Barlow Semi Condensed"],
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        fontFamily: "Barlow Semi Condensed",
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: "Barlow Semi Condensed",
      },
    },
    MuiInputBase: {
      root: {
        width: "100%",
      },
    },
    MuiAppBar: {
      root: {
        flexGrow: 1,
      },
    },
  },
  props: {},
})
