import React, { useState } from "react"
import Fab from "@material-ui/core/Fab"
import MusicNoteIcon from "@material-ui/icons/MusicNote"
import { makeStyles } from "@material-ui/core/styles"
import MusicOffIcon from "@material-ui/icons/MusicOff"
import useSound from "use-sound"
import song from "./sound.mp3"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    zIndex: 10000,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: "black",
    borderColor: "white",
    color: "white",
    border: "2px solid",
    "&:hover": {
      backgroundColor: "#262626",
    },
  },
}))

const MusicButton = () => {
  const [icon, setIcon] = useState(true)
  const classes = useStyles()

  const [play, { pause, isPlaying }] = useSound(song, {
    loop: true,
    interrupt: true,
    volume: 0.4,
  })

  const handleClick = e => {
    setIcon(!icon)
    //Comprobar si la musica ya esta puesta
    if (!isPlaying) {
      //Empezar musica
      play()
    } else {
      // Para musica
      // using stop() doesnt remember progress, but hogs less resources
      pause()
    }
  }

  return (
    <Fab
      className={classes.fab}
      color="primary"
      aria-label="add"
      onClick={handleClick}
    >
      {icon === false ? <MusicNoteIcon /> : <MusicOffIcon />}
    </Fab>
  )
}

export default MusicButton
